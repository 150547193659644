// This Source Code Form is subject to the terms of the Mozilla Public
// License, v2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/
export default class LocalStorageAppConfiguration {
    constructor({ defaults }) {
        this.changeListeners = new Map();
        this.defaults = defaults;
    }
    get(key) {
        const value = localStorage.getItem(LocalStorageAppConfiguration.KEY_PREFIX + key);
        try {
            return value == undefined ? this.defaults?.[key] : JSON.parse(value);
        }
        catch {
            return undefined;
        }
    }
    async set(key, value) {
        if (value == undefined) {
            localStorage.removeItem(LocalStorageAppConfiguration.KEY_PREFIX + key);
        }
        else {
            localStorage.setItem(LocalStorageAppConfiguration.KEY_PREFIX + key, JSON.stringify(value) ?? "");
        }
        const listeners = this.changeListeners.get(key);
        if (listeners) {
            // Copy the list of listeners to protect against mutation during iteration
            [...listeners].forEach((listener) => listener(value));
        }
    }
    addChangeListener(key, cb) {
        let listeners = this.changeListeners.get(key);
        if (!listeners) {
            listeners = new Set();
            this.changeListeners.set(key, listeners);
        }
        listeners.add(cb);
    }
    removeChangeListener(key, cb) {
        const listeners = this.changeListeners.get(key);
        if (listeners) {
            listeners.delete(cb);
        }
    }
}
LocalStorageAppConfiguration.KEY_PREFIX = "studio.app-configuration.";
